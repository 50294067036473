<template>
  <div id="start">
    <div class="d-md-none mobile" v-if="appIn && $route.path != '/404' && getMobileOS() != 'iOS'">
      <div class="d-flex">
        <img src="@/assets/img/m-logo.svg" alt="logo" />
        <div class="ms-2">
          <div class="font-weight-bold primary--text">{{ $t('snobar') }}</div>
          <div class="text-body-2">{{ $t('openSnobar') }}</div>
        </div>
      </div>
      <v-spacer></v-spacer>
      <a
        :href="
          getMobileOS() == 'iOS'
            ? 'https://apps.apple.com/ua/app/snobar/id1613143471'
            : 'https://play.google.com/store/apps/details?id=app.snobar.client'
        "
        target="_blank"
      >
        <v-btn width="80" rounded depressed class="blue white--text">{{ $t('btn.install') }}</v-btn>
      </a>
    </div>
    <v-app-bar
      v-if="$route.path == '/404'"
      elevation="0"
      :max-height="screenWidth >= 960 ? 60 : 42"
      color="primary"
      class="d-flex align-center"
    >
      <router-link to="/" class="d-flex align-center justify-center">
        <img v-if="screenWidth >= 960 || $route.path == '/404'" class="mx-md-0 mx-auto" src="@/assets/img/logo.svg" alt="logo" />
        <img v-else class="mx-auto" src="@/assets/img/logo-b.svg" alt="logo" />
      </router-link>
      <v-spacer v-if="screenWidth >= 960"></v-spacer>
      <router-link v-if="screenWidth >= 960" to="/privacy-policy" class="white--text font-weight-bold">{{ $t('privacy') }}</router-link>
      <router-link v-if="screenWidth >= 960" to="/terms-and-conditions" class="ms-12 white--text font-weight-bold">
        {{ $t('terms') }}
      </router-link>
    </v-app-bar>
    <header class="home-header" v-else-if="$route.name == 'Home'">
      <v-spacer class="d-sm-none"></v-spacer>
      <router-link to="/" class="d-flex align-center justify-center">
        <img height="24" width="233" src="@/assets/img/logo-b.svg" alt="logo" />
      </router-link>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs, value }">
          <v-btn depressed dark text v-bind="attrs" v-on="on" height="43" class="rounded-lg d-sm-inline-flex d-none" color="transparent">
            <span class="f18 font-weight-medium" :class="screenWidth <= 960 ? 'gray--text' : 'accent--text'">
              {{ $t(`header.${select.key}`) }}
              <v-icon class="mt-n1">{{ !value ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
            </span>
          </v-btn>
        </template>
        <v-list class="px-5 py-2">
          <v-list-item v-for="item in lang" :key="item.key" class="menu-item" @click="changeLang(item)">
            {{ $t(`header.${item.key}`) }}
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn to="/feed" depressed class="primary rounded-lg ms-7 d-sm-inline-flex d-none px-4" height="43">
        <span class="accent--text f17">{{ $t('btn.goToSnobar') }}</span>
      </v-btn>
    </header>
    <header :class="$route.name == 'Feed' ? 'feed-header' : 'other-header'" v-else>
      <router-link to="/" class="d-flex align-center justify-center">
        <img class="logo" height="24" width="223" src="@/assets/img/logo-b.svg" alt="logo" />
      </router-link>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs, value }">
          <v-btn depressed dark text v-bind="attrs" v-on="on" height="43" class="rounded-lg" color="transparent">
            <span class="f18 font-weight-medium primary--text">
              {{ $t(`header.${select.key}`) }}
              <v-icon class="mt-n1">{{ !value ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
            </span>
          </v-btn>
        </template>
        <v-list class="px-5 py-2">
          <v-list-item v-for="item in lang" :key="item.key" class="menu-item" @click="changeLang(item)">
            {{ $t(`header.${item.key}`) }}
          </v-list-item>
        </v-list>
      </v-menu>
      <div class="header-name" v-if="$route.name == 'Feed'">{{ $t('feed') }}</div>
      <div class="header-name" v-else-if="$route.name == 'Question'">{{ $t('question') }}</div>
      <router-link v-else-if="$route.name == 'Post'" class="header-name" to="/feed">
        <img class="me-2" :class="select.key == 'ar' ? 'back' : ''" src="@/assets/icon/left-arrow.svg" alt="arrow" />{{ $t('post') }}
      </router-link>
      <div style="max-width: 700px" class="header-name page link" v-else @click="$router.go(-1)">
        <img class="me-2" :class="select.key == 'ar' ? 'back' : ''" src="@/assets/icon/left-arrow.svg" alt="arrow" />{{ $route.name }}
      </div>
      <div class="me-8 app-header justify-space-between flex-wrap">
        <a href="https://apps.apple.com/us/app/snobar/id1613143471" target="_blank" class="d-block me-2" style="height: 42px">
          <img
            height="42"
            width="137"
            :src="require(`@/assets/icon/apple${select.key == 'ar' ? '-ar' : ''}.png`)"
            alt="apple"
            :srcset="getImgSet(select.key == 'ar' ? 'apple-ar' : 'apple')"
          />
        </a>
        <a href="https://play.google.com/store/apps/details?id=app.snobar.client" target="_blank" class="d-block ms-2" style="height: 42px">
          <img
            height="42"
            width="137"
            :src="require(`@/assets/icon/google${select.key == 'ar' ? '-ar' : ''}.png`)"
            alt="google"
            :srcset="getImgSet(select.key == 'ar' ? 'google-ar' : 'google')"
          />
        </a>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: [{ key: 'ar' }, { key: 'en' }],
    };
  },
  methods: {
    changeLang(item) {
      localStorage.setItem('lang', JSON.stringify(item));
      this.$store.dispatch('setLang', item);
    },
    getMobileOS() {
      const ua = navigator.userAgent;
      if (/android/i.test(ua)) {
        return 'Android';
      } else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
        return 'iOS';
      }
    },
    getImgSet(img) {
      return `
        ${require(`@/assets/icon/${img}-2x.png`)} 2x,
        ${require(`@/assets/icon/${img}-3x.png`)} 3x
      `;
    },
  },
  computed: {
    appIn() {
      return this.$route.query.hide_app_install == 'true' ? false : true;
    },
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    select() {
      return this.$store.getters.langValue;
    },
  },
};
</script>

<style lang="scss">
header {
  width: 100%;
  padding: 0 60px;
  display: flex;
  align-items: center;
  height: 72px;
  .header-name {
    height: 71px;
    position: absolute;
    display: flex;
    align-items: center;
    max-width: 572px;
    width: 100%;
    padding: 0 40px;
    left: 50%;
    transform: translate(-50%, 0);
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    color: var(--v-primary-base) !important;
    font-weight: 700;
    font-size: 20px;
  }
  .app-header {
    display: none;
  }
}
.feed-header,
.other-header {
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
}
.home-header {
  background: linear-gradient(to right, white 57%, var(--v-blue-base) 43%);
}
.v-toolbar__content {
  max-width: 1272px;
  width: 100%;
  margin: 0 auto;
}
.v-application--is-rtl {
  .home-header {
    background: linear-gradient(to left, white 57%, var(--v-blue-base) 43%);
  }
}
@media screen and (max-width: 1280px) {
  header {
    padding: 0 40px;
  }
}
@media screen and (max-width: 1240px) {
  .feed-header {
    .app-header {
      display: flex;
    }
    .header-name {
      display: none;
    }
  }
  .other-header {
    .app-header,
    .v-btn,
    .logo {
      display: none;
    }
  }
}
@media screen and (max-width: 960px) {
  header {
    padding: 0 24px;
    .app-header {
      display: none !important;
    }
    .header-name {
      padding: 0 16px;
    }
  }
  .home-header {
    background: white;
  }
  .v-application--is-rtl {
    .home-header {
      background: white;
    }
  }
}
@media screen and (max-width: 600px) {
  header {
    padding: 0 16px;
    height: 64px;
  }
}
</style>
