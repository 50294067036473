import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    rtl: false,
    options: { customProperties: true },
    themes: {
      light: true,
      light: {
        primary: '#20264d',
        secondary: '#dc1d6d',
        info: '#f1f5fa',
        accent: '#f5f5f5',
        gray: {
          base: '#444c55',
          lighten5: '#acacac',
        },
        blue: '#2c40b5',
      },
    },
  },
});
