import '@/plugins/axios';
import router from '@/router/index';

const api = '/person/api/v1/posts/';

export default {
  state: {
    post: { created_by: { city: {}, country: {} }, attachments: [], topics: [] },
    postComentList: {},
  },
  actions: {
    getPost(context) {
      return axios.get(`${api}${router.app._route.params.postId}`).then((res) => {
        context.commit('GET_POST', res.data);
      });
    },
    setPost: (context) => {
      context.commit('GET_POST', { created_by: { city: {}, country: {} }, attachments: [], topics: [] });
    },
    getPostComent(context, payload) {
      return axios.get(`${api}${router.app._route.params.postId}/comments/?page=${payload}`).then((res) => {
        context.commit('GET_POSTCOMENTLIST', res.data);
      });
    },
    setPostComent: (context) => {
      context.commit('GET_POSTCOMENTLIST', {});
    },
    getPostComentReplies(context, { id, page }) {
      return axios.get(`${api}${router.app._route.params.postId}/comments/${id}/replies/?page=${page}`);
    },
  },
  mutations: {
    GET_POST: (state, payload) => {
      state.post = payload;
      return state.post;
    },
    GET_POSTCOMENTLIST: (state, payload) => {
      state.postComentList = payload;
      return state.postComentList;
    },
  },
  getters: {
    post(state) {
      return state.post;
    },
    postComentList(state) {
      return state.postComentList;
    },
  },
};
