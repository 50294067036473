<template>
  <div>
    <section class="d-flex d-sm-none justify-center py-2">
      <a href="https://apps.apple.com/us/app/snobar/id1613143471" target="_blank" class="d-block me-2" style="height: 40px">
        <img height="40" width="132" :src="require(`@/assets/icon/apple${select.key == 'ar' ? '-ar' : ''}.png`)" alt="apple" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=app.snobar.client" target="_blank" class="d-block ms-2" style="height: 40px">
        <img height="40" width="132" :src="require(`@/assets/icon/google${select.key == 'ar' ? '-ar' : ''}.png`)" alt="google" />
      </a>
    </section>
    <footer class="primary">
      <img class="d-block mx-sm-0 mx-auto" width="272" height="28" src="@/assets/img/logo.svg" alt="logo" />
      <div class="mt-8 d-sm-flex">
        <section class="d-sm-block d-flex justify-space-around">
          <div class="font-weight-bold text-h5 accent--text d-sm-block d-none">{{ $t('footer.legal') }}</div>
          <router-link to="/privacy-policy" class="f18 font-weight-medium accent--text d-block">{{ $t('footer.privacy') }}</router-link>
          <router-link to="/terms-and-conditions" class="f18 font-weight-medium accent--text d-block">{{ $t('footer.terms') }}</router-link>
        </section>
        <v-spacer></v-spacer>
        <section class="social-list">
          <div class="d-flex mb-4 justify-sm-start justify-center">
            <a v-for="(i, j) in socialList" :key="i.icon" :href="i.link" target="_blank" :class="j ? 'ms-4' : ''">
              <img :src="require(`@/assets/icon/${i.icon}.svg`)" :alt="i.icon" />
            </a>
          </div>
          <a href="mailto:hello@snobar.app" target="_blank" class="accent--text f18 font-weight-medium">hello@snobar.app</a>
        </section>
        <section class="d-sm-flex d-none flex-md-row flex-column">
          <a
            href="https://apps.apple.com/us/app/snobar/id1613143471"
            target="_blank"
            class="d-block me-md-2 mb-md-0 mb-4"
            style="height: 42px"
          >
            <img
              height="42"
              width="139"
              :src="require(`@/assets/icon/apple${select.key == 'ar' ? '-ar' : ''}.png`)"
              alt="apple"
              :srcset="getImgSet(select.key == 'ar' ? 'apple-ar' : 'apple')"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=app.snobar.client"
            target="_blank"
            class="d-block ms-md-2"
            style="height: 42px"
          >
            <img
              height="42"
              width="139"
              :src="require(`@/assets/icon/google${select.key == 'ar' ? '-ar' : ''}.png`)"
              alt="google"
              :srcset="getImgSet(select.key == 'ar' ? 'google-ar' : 'google')"
            />
          </a>
        </section>
      </div>
      <div class="d-sm-none text-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs, value }">
            <v-btn depressed dark text v-bind="attrs" v-on="on" height="44" class="rounded-lg">
              <span class="font-weight-medium accent-text">
                {{ $t(`header.${select.key}`) }}
                <v-icon class="mt-n1">{{ !value ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
              </span>
            </v-btn>
          </template>
          <v-list class="px-5 py-2">
            <v-list-item v-for="item in lang" :key="item.key" class="menu-item" @click="changeLang(item)">
              {{ $t(`header.${item.key}`) }}
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="text-center font-weight-light white--text mt-8">{{ $t('footer.copyright') }} {{ new Date().getFullYear() }}©</div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: [{ key: 'ar' }, { key: 'en' }],
      socialList: [
        { icon: 'whats-app', link: 'https://wa.me/message/OHUE3CIDZFTTI1' },
        { icon: 'facebook', link: 'https://www.facebook.com/snobarapp?mibextid=LQQJ4d' },
        { icon: 'twitter', link: 'https://twitter.com/snobar_app' },
        { icon: 'instagram', link: 'https://instagram.com/snobarapp?igshid=YmMyMTA2M2Y=' },
      ],
    };
  },
  mounted() {
    this.axios.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang')
      ? JSON.parse(localStorage.getItem('lang')).key
      : 'en';
    this.$i18n.locale = localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')).key : 'en';
    this.$vuetify.rtl = localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')).key == 'ar' : false;
  },
  methods: {
    changeLang(item) {
      localStorage.setItem('lang', JSON.stringify(item));
      this.$store.dispatch('setLang', item);
    },
    getImgSet(img) {
      return `
        ${require(`@/assets/icon/${img}-2x.png`)} 2x,
        ${require(`@/assets/icon/${img}-3x.png`)} 3x
      `;
    },
  },
  computed: {
    select() {
      return this.$store.getters.langValue;
    },
  },
};
</script>

<style lang="scss">
footer {
  padding: 40px 60px 25px;
  .social-list {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
  @media screen and (max-width: 1280px) {
    padding: 40px 40px 25px;
  }
  @media screen and (max-width: 960px) {
    padding: 40px 24px 16px;
  }
  @media screen and (max-width: 599px) {
    padding: 24px 16px 16px;
    .social-list {
      text-align: center;
      margin: 40px auto;
      position: initial;
      left: initial;
      transform: initial;
    }
  }
}
</style>
