import '@/plugins/axios';

const api = '/api/v1/';

export default {
  state: {
    langValue: localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')) : { key: 'en' },
    counter: {},
  },
  actions: {
    setLang: (context, payload) => {
      context.commit('GET_LANG', payload);
    },
    getCounter(context) {
      return axios.get(`${api}counters`).then((res) => {
        context.commit('GET_COUNTER', res.data);
      });
    },
    setCounter: (context) => {
      context.commit('GET_COUNTER', {});
    },
  },
  mutations: {
    GET_LANG: (state, payload) => {
      state.langValue = payload;
      return state.langValue;
    },
    GET_COUNTER: (state, payload) => {
      state.counter = payload;
      return state.counter;
    },
  },
  getters: {
    langValue(state) {
      return state.langValue;
    },
    counter(state) {
      return state.counter;
    },
  },
};
