import '@/plugins/axios';
import router from '@/router/index';

const api = '/person/api/v1/questions/';

export default {
  state: {
    question: { created_by: { city: {}, country: {} }, attachments: [], category: {} },
    questionAnswersList: {},
  },
  actions: {
    getQuestion(context) {
      return axios.get(`${api}${router.app._route.params.questionId}`).then((res) => {
        context.commit('GET_QUESTION', res.data);
      });
    },
    setQuestion: (context, payload) => {
      context.commit('GET_QUESTION', payload);
    },
    getQuestionAnswers(context, payload) {
      return axios.get(`${api}${router.app._route.params.questionId}/answers/?page=${payload}`).then((res) => {
        context.commit('GET_QUESTIONANSWERSLIST', res.data);
      });
    },
    setQuestionAnswers: (context, payload) => {
      context.commit('GET_QUESTIONANSWERSLIST', payload);
    },
  },
  mutations: {
    GET_QUESTION: (state, payload) => {
      state.question = payload;
      return state.question;
    },
    GET_QUESTIONANSWERSLIST: (state, payload) => {
      state.questionAnswersList = payload;
      return state.questionAnswersList;
    },
  },
  getters: {
    question(state) {
      return state.question;
    },
    questionAnswersList(state) {
      return state.questionAnswersList;
    },
  },
};
