import Vue from 'vue';
import Vuex from 'vuex';
import common from './modules/common';
import post from './modules/post';
import question from './modules/question';
import feed from './modules/feed';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    common,
    post,
    question,
    feed,
  },
});
