<template>
  <v-app :key="key">
    <Header />
    <v-divider class="d-md-none" v-if="this.$route.path != '/404'"></v-divider>
    <router-view />
    <v-spacer></v-spacer>
    <Footer v-if="this.$route.path != '/404'" />
  </v-app>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      key: 1,
    };
  },
  watch: {
    lang() {
      this.key = ++this.key;
    },
  },
  computed: {
    lang() {
      return this.$store.getters.langValue.key;
    },
  },
};
</script>

<style lang="scss">
#app * {
  font-family: 'Cairo', sans-serif !important;
}
body,
#app {
  background: #ffffff;
}
a {
  text-decoration: none;
  color: var(--v-blue-base) !important;
}
.f15 {
  font-size: 15px !important;
}
.f17 {
  font-size: 17px;
}
.f18 {
  font-size: 18px;
  font-weight: 700;
}
.f22 {
  font-size: 22px;
}
.h3 {
  font-size: 36px;
  font-weight: 700;
}
.f38 {
  font-size: 38px;
}
.f42 {
  font-size: 42px;
  font-weight: 700;
}
.f56 {
  font-size: 56px;
  font-weight: 800;
  line-height: 1.2 !important;
}
.wrap {
  max-width: 572px;
  width: 100%;
  margin: 0 auto;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
.wrap-big {
  max-width: 1160px !important;
  width: 100%;
  margin: 0 auto;
}
.wrap-main {
  max-width: 1440px;
  padding-left: 60px;
  padding-right: 60px;
  width: 100%;
  margin: 0 auto;
}
.menu-item {
  min-height: 30px !important;
  padding: 8px;
  // img {
  //   border-radius: 50%;
  //   object-fit: cover;
  // }
}
.v-btn__content {
  text-transform: none;
  letter-spacing: initial !important;
}
.link {
  cursor: pointer;
}
.back {
  transform: rotate(180deg);
}
.like-color {
  filter: invert(15%) sepia(81%) saturate(3641%) hue-rotate(321deg) brightness(105%) contrast(91%);
}
.arrow-start {
  position: fixed;
  bottom: 20px;
  left: 71%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50% !important;
  background: #f1f5fa;
  box-shadow: 2px 3px 8px rgba(105, 105, 105, 0.12);
  img {
    transform: rotate(-90deg);
    width: 30px;
    height: 30px;
  }
}
.mobile {
  display: flex;
  align-items: center;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding: 14px 16px;
  img {
    border-radius: 11px;
  }
  .v-btn__content {
    font-size: 14px;
    text-transform: none;
    opacity: 1 !important;
  }
}
.v-skeleton-loader__image {
  height: 300px !important;
}
.navigate-dot {
  display: flex;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  margin-top: 4px;
  // bottom: 42px;
  button {
    width: 10px;
  }
  .v-icon::after {
    content: initial !important;
  }
}
.cars-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  min-width: 32px;
  margin-top: 4px;
  background: #f5f5f5;
  border: 0.5px solid #e0e0e0;
  box-shadow: 1px 1px 2px rgba(3, 3, 3, 0.08);
  border-radius: 50%;
}
.cars-item:not(:first-child) {
  margin-left: -8px;
}
.topic-item {
  display: flex;
  align-items: center;
  padding: 0 12px 0 8px;
  height: 32px;
  min-width: 32px;
  margin-top: 4px;
  border: 1px solid #e0e0e0;
  border-radius: 18px;
}
.topic-item:not(:last-child) {
  margin-right: 12px;
}
.v-window__prev,
.v-window__next {
  background: none !important;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.5));
}
.v-application--is-rtl {
  .cars-item:not(:first-child) {
    margin-right: -8px;
    margin-left: initial;
  }
  .topic-item {
    padding: 0 8px 0 12px;
  }
  .topic-item:not(:last-child) {
    margin-left: 12px;
    margin-right: initial;
  }
}
@media screen and (max-width: 1280px) {
  .wrap-main {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (max-width: 960px) {
  .arrow-start {
    left: initial;
    right: 16px;
  }
  .wrap-main {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media screen and (max-width: 600px) {
  .wrap-main {
    padding-left: 16px;
    padding-right: 16px;
  }
}
</style>
