import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '',
      name: 'Home',
      component: () => import('@/views/Home.vue'),
    },
    {
      path: '/feed',
      name: 'Feed',
      component: () => import('@/views/Feed.vue'),
    },
    {
      path: '/posts/:postId',
      name: 'Post',
      component: () => import('@/views/Post.vue'),
    },
    {
      path: '/questions/:questionId',
      name: 'Question',
      component: () => import('@/views/Question.vue'),
    },
    {
      path: '/privacy-policy',
      name: 'Privacy Policy',
      component: () => import('@/views/Privacy.vue'),
    },
    {
      path: '/terms-and-conditions',
      name: 'Terms and Conditions',
      component: () => import('@/views/Terms.vue'),
    },
    {
      path: '/404',
      name: 'Not found page',
      component: () => import('@/views/NotFound.vue'),
    },
    {
      path: '*',
      redirect: { name: 'Not found page' },
    },
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
