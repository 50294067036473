import '@/plugins/axios';

const api = '/person/api/v1.1/feed/';

export default {
  state: {
    feedList: { results: [] },
  },
  actions: {
    getFeedList(context, payload) {
      return axios.get(`${api}${payload > 1 ? `?page=${payload - 1}` : ''}`).then((res) => {
        context.commit('GET_FEEDLIST', res.data);
      });
    },
    setFeedList: (context) => {
      context.commit('GET_FEEDLIST', { results: [] });
    },
  },
  mutations: {
    GET_FEEDLIST: (state, payload) => {
      state.feedList = payload;
      return state.feedList;
    },
  },
  getters: {
    feedList(state) {
      return state.feedList;
    },
  },
};
